*{
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
body {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(19, 19, 19);
}

