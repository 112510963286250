.foot{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    margin-top: 200px;
    position: relative;
}
.foot .foot-top{
    background: rgb(83, 83, 183);
    width: 75%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.foot .foot-top input{
    width: 50%;
    outline: none;
    background: none;
    border: none;
    border-bottom: 1px solid black;
    font-size: 18px;
}
.foot .foot-top button{
    padding: 10px 20px 10px 20px;
    color: rgb(223, 172, 53);
    background: none;
    cursor: pointer;
}
.foot .foot-top .top{
    position: absolute;
    left: 0;
    top:-175px;
    font-size: 100px;
    text-transform: uppercase;
    font-weight: bold;

}
.copy{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    margin-top: 50px;
}
.social{
    display: flex;
    gap: 10px;
}
.social i{
    font-size: 18px;
    cursor: pointer;
}

@media screen and (max-width:868px){
    .foot .foot-top .top{
        font-size: 75px;
        top:-125px;
    }
    .copy{
        display: flex;
        flex-direction: column;
    }
    .mid{
        margin-top: 10px;
        margin-bottom: 10px;
    }
}