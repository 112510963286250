.v-card-rights{
    color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    margin-top: 200px;
}
.v-card-left-right{

}
.v-box-right {
    width: 500px;
    height: 400px;
    /* border: 1px solid yellow; */
    position: relative;
}
.v-box-right video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 100;
}
.v-box-right .text-right{
    position: absolute;
    top: -75px;
    right: -75px;
    text-align: right;
}
.v-box-right .text-right .top-right{
    font-size: 125px;
    font-weight: bolder;
    text-transform: uppercase;
   color: rgb(226, 147, 43);

}
.v-box-right .text-right .btm-right{
    font-size: 125px;
    font-weight: bolder;
    text-transform: uppercase;
    

}
.v-card-right-right{
    width: 400px;
    /* border: 1px solid yellow; */
}
.v-card-right-right h1{
    width: 100%;
    font-size: 25px;
}
.v-card-right-right p{
    width: 100%;
    margin-top: 10px;
    color: gray;
    
}

.v-card-left-right .button1 button{
    padding: 10px 20px 10px 20px;
    text-transform: capitalize;
    border: none;
    outline: none;
    background: rgb(255, 159, 70);
    color: #fff;
    cursor: pointer;
    position: absolute;
    top:250px;
    right: 0;
}


@media screen and (max-width:868px){
    .v-card-rights{
        /* border: 1px solid rgb(255, 61, 94); */
        flex-direction: column-reverse;
    }
    .v-card-left-right,.v-card-right-right{
        align-self: flex-end;
    }
    .v-box-right  {
        width: 400px;
        height: 300px;
        /* border: 1px solid yellow; */
        position: relative;
    }
    .v-box-right video{
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 100;
    }
    .v-box-right .text-right{
        position: absolute;
        top: -75px;
        right: -25px;
    }
    .v-box-right .text-right .top-right{
        font-size: 100px;
    }
    .v-box-right .text-right .btm-right{
        font-size: 100px;
    }
    .v-card-right-right{
        width: 400px;
    }
    .v-card-right-right h1{
        width: 100%;
        font-size: 25px;
    }
    .v-card-right-right p{
        width: 100%;
        margin-top: 10px;
        color: gray;
    }
}

@media screen and (max-width:540px){
    .v-card-rights{
        /* border: 1px solid rgb(255, 61, 94); */
        flex-direction: column-reverse;
    }
    .v-card-left-right,.v-card-right-right{
        align-self: flex-end;
    }
    .v-box-right  {
        width:250px;
        height: 200px;
        /* border: 1px solid yellow; */
        position: relative;
    }
    .v-box-right video{
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 100;
    }
    .v-box-right .text-right{
        position: absolute;
        top: -75px;
        right: -25px;
    }
    .v-box-right .text-right .top-right{
        font-size: 50px;
    }
    .v-box-right .text-right .btm-right{
        font-size:50px;
    }
    .v-card-right-right{
        width: 200px;
    }
    .v-card-right-right h1{
        font-size: 18px;
        width: 100%;
    }
    .btn-right{
        position: absolute;
        top: 100px;
        right: 0;
    }
}